import "./KintoButton.css"

import { Button, type ButtonProps } from "antd"
import React from "react"

type OwnProps = {
  theme?: KintoButtonThemes
}

export enum KintoButtonThemes {
  text = "text",
  outlined = "default",
  contained = "primary"
}

const Header = (props: OwnProps & ButtonProps): React.ReactElement => (
  <Button
    size="large"
    shape="round"
    type={props.theme ?? KintoButtonThemes.outlined}
    className="kinto-button"
    {...props}
  >{props.children}</Button>
)

export default Header
