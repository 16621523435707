/**
 * Action types
 */
export enum OneTypes {
  "SET_DATA" = "@ONE/SET_DATA",
  "CLEAR_DATA" = "@ONE/CLEAR_DATA",

  "SEND_SURVEY_REQUEST" = "@ONE/SEND_SURVEY_REQUEST",
  "SEND_SURVEY_SUCCESS" = "@ONE/SEND_SURVEY_SUCCESS",
  "SEND_SURVEY_FAILURE" = "@ONE/SEND_SURVEY_FAILURE",

  "SEARCH_PLATE_REQUEST" = "@ONE/SEARCH_PLATE_REQUEST",
  "SEARCH_PLATE_SUCCESS" = "@ONE/SEARCH_PLATE_SUCCESS",
  "SEARCH_PLATE_FAILURE" = "@ONE/SEARCH_PLATE_FAILURE",
}

/**
 * Data types
 */
export interface OneSurveyData {
  loading: boolean
  response?: ISendOneDataResponse
}

export interface PlateData {

  loading: boolean
  response?: IOnePlateDataResponse
}

/**
 * State type
 */
export interface OneState {
  readonly sendSurveyOne: OneSurveyData
  readonly plate: PlateData
}
