import axios, { type AxiosResponse } from "axios"

const { REACT_APP_SURVEY_ONE_API } = process.env
async function SendSurvey (uid: string, grade: number, comments?: string): Promise<AxiosResponse> {
  const response = await axios.put(`${REACT_APP_SURVEY_ONE_API}/one`, { uid, grade, comments })

  return response
}

async function GetPlateInfo (plate: string): Promise<AxiosResponse> {
  const response = await axios.get(`${REACT_APP_SURVEY_ONE_API}/plate/${plate}`)

  return response
}

export default {
  SendSurvey,
  GetPlateInfo
}
