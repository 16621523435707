import { all, takeLatest } from "redux-saga/effects"

import { getPlateInfo, sendSurveyOne } from "./one/sagas"
import { OneTypes } from "./one/types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function * rootSaga (): Generator {
  return yield all([
    takeLatest(OneTypes.SEND_SURVEY_REQUEST, sendSurveyOne),
    takeLatest(OneTypes.SEARCH_PLATE_REQUEST, getPlateInfo)
  ])
}
