import { action } from "typesafe-actions"

import { type SurveyOnePayload } from "./sagas"
import { type OneSurveyData, OneTypes } from "./types"

export const setData = (data: OneSurveyData): { type: OneTypes.SET_DATA } => action(OneTypes.SET_DATA, { data })
export const clearData = (): { type: OneTypes.CLEAR_DATA } => action(OneTypes.CLEAR_DATA)

export const sendSurvey = (data: SurveyOnePayload): { type: OneTypes.SEND_SURVEY_REQUEST } => action(OneTypes.SEND_SURVEY_REQUEST, data)
export const sendSurveySuccess = (data: ISendOneDataResponse<ISendOneDataResponseData>): { type: OneTypes.SEND_SURVEY_SUCCESS } => action(OneTypes.SEND_SURVEY_SUCCESS, data)
export const sendSurveyFailure = (data: ISendOneDataResponse<Error>): { type: OneTypes.SEND_SURVEY_FAILURE } => action(OneTypes.SEND_SURVEY_FAILURE, data)

export const searchPlate = (data: { plate: string }): { type: OneTypes.SEARCH_PLATE_REQUEST } => action(OneTypes.SEARCH_PLATE_REQUEST, data)
export const searchPlateSuccess = (data: ISendOneDataResponse<ISendOneDataResponseData>): { type: OneTypes.SEARCH_PLATE_SUCCESS } => action(OneTypes.SEARCH_PLATE_SUCCESS, data)
export const searchPlateFailure = (data: ISendOneDataResponse<Error>): { type: OneTypes.SEARCH_PLATE_FAILURE } => action(OneTypes.SEARCH_PLATE_FAILURE, data)

export default {
  setData,
  clearData,

  sendSurvey,
  sendSurveySuccess,
  sendSurveyFailure,

  searchPlate,
  searchPlateSuccess,
  searchPlateFailure
}
