import "./index.css"

import React from "react"

import LogoKintoBrasil from "../../assets/LogoKintoBrasil.svg"

// type OwnProps = {
// }

const GenericHeader = (props: React.HTMLAttributes<never>): React.ReactElement => {
  return (
    <div id="generic-header-container" {...props}>
      <img className="generic-header-logo" src={LogoKintoBrasil}/>
    </div>
  )
}

export default GenericHeader
