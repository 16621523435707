import React from "react"
import { createBrowserRouter } from "react-router-dom"

import SentSurvey from "./views/SentSurvey"
import Survey from "./views/Survey"
import SurveyOne from "./views/SurveyOne"

function router () {
  return createBrowserRouter([
    {
      path: "/",
      element: (<Survey />),
      children: [
        {
          path: "already-done",
          element: <span style={{ fontSize: "24px" }}>Pesquisa já respondida anteriormente.<br/>Obrigado!</span>
        },
        {
          path: "one",
          children: [
            {
              path: ":uid",
              element: <SurveyOne redirectTo="done"/>
            },
            {
              path: ":uid/done",
              element: <SentSurvey />
            }
          ]
        },
        {
          path: "",
          element: <span style={{ fontSize: "24px" }}>Pagina não encontrada!</span>
        },
        {
          path: "*",
          element: <span style={{ fontSize: "24px" }}>Pagina não encontrada!</span>
        }
      ]
    }
  ])
}

export default router
