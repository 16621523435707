import { AxiosError, type AxiosResponse } from "axios"
import { call, put } from "redux-saga/effects"
import { type PayloadAction } from "typesafe-actions"

import SurveyOneAPI from "../../../apis/SurveyOneAPI"
import { searchPlateFailure, searchPlateSuccess, sendSurveyFailure, sendSurveySuccess } from "./actions"
import { type OneTypes } from "./types"

export type SurveyOnePayload = {
  uid: string
  grade: number
  comments?: string
}

export type PlateInfoPayload = {
  plate: string
}

export function * sendSurveyOne (
  action: PayloadAction<OneTypes, SurveyOnePayload>
): Generator {
  try {
    const { uid, grade, comments } = action.payload

    const surveyResponse = (yield call(SurveyOneAPI.SendSurvey, uid, grade, comments)) as AxiosResponse

    yield put(sendSurveySuccess({
      code: surveyResponse.status,
      data: surveyResponse.data,
      message: surveyResponse.data?.message,
      error: false
    }))
  } catch (err) {
    let errorMessage: string = "Ocorreu um erro, tente novamente mais tarde!"
    let errorCode: number = 500
    let errorData: Error = new Error(errorMessage)

    if (err instanceof AxiosError) {
      errorMessage = err.response?.data?.message ?? ""
      errorCode = err.response?.status ?? 500
      errorData = err.response?.data ?? err
    } else if (err instanceof Error) {
      errorMessage = err.message
      errorData = err
    }

    yield put(
      sendSurveyFailure({
        code: errorCode,
        error: true,
        errorCode,
        message: errorMessage,
        data: errorData
      })
    )
  }
}

export function * getPlateInfo (
  action: PayloadAction<OneTypes, PlateInfoPayload>
): Generator {
  try {
    const { plate } = action.payload

    const surveyResponse = (yield call(SurveyOneAPI.GetPlateInfo, plate)) as AxiosResponse

    yield put(searchPlateSuccess({
      code: surveyResponse.status,
      data: surveyResponse.data,
      message: surveyResponse.data?.message,
      error: false
    }))
  } catch (err) {
    let errorMessage: string = "Ocorreu um erro, tente novamente mais tarde!"
    let errorCode: number = 500
    let errorData: Error = new Error(errorMessage)

    if (err instanceof AxiosError) {
      errorMessage = err.response?.data?.message ?? ""
      errorCode = err.response?.status ?? 500
      errorData = err.response?.data ?? err
    } else if (err instanceof Error) {
      errorMessage = err.message
      errorData = err
    }

    yield put(
      searchPlateFailure({
        code: errorCode,
        error: true,
        errorCode,
        message: errorMessage,
        data: errorData
      })
    )
  }
}
