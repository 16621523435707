import "./index.css"

import React from "react"
import { v4 as uuid } from "uuid"

type OwnProps = {
  value?: number
  onChange?: (value?: number) => void
}

const SurveyGrade: React.FC<OwnProps> = (props): React.ReactElement => {
  const elementId = uuid()

  const handleSelection = (event: React.MouseEvent<HTMLElement>) => {
    const classList = event.currentTarget.classList
    let newValue: number | undefined

    if (classList.contains("selected")) {
      newValue = undefined
    } else {
      newValue = Number(event.currentTarget.innerText)
    }

    if (props.onChange) {
      props.onChange(newValue)
    }
  }

  return (
    <div className="survey-grade-container">
      <ul id={elementId}>
        <li id="grade-1" className={`grade ${props.value ? (props.value === 1 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>1</span></li>
        <li id="grade-2" className={`grade ${props.value ? (props.value === 2 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>2</span></li>
        <li id="grade-3" className={`grade ${props.value ? (props.value === 3 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>3</span></li>
        <li id="grade-4" className={`grade ${props.value ? (props.value === 4 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>4</span></li>
        <li id="grade-5" className={`grade ${props.value ? (props.value === 5 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>5</span></li>
        <li id="grade-6" className={`grade ${props.value ? (props.value === 6 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>6</span></li>
        <li id="grade-7" className={`grade ${props.value ? (props.value === 7 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>7</span></li>
        <li id="grade-8" className={`grade ${props.value ? (props.value === 8 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>8</span></li>
        <li id="grade-9" className={`grade ${props.value ? (props.value === 9 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>9</span></li>
        <li id="grade-10" className={`grade ${props.value ? (props.value === 10 ? "selected" : "not-selected") : ""}`} onClick={handleSelection}><span>10</span></li>
      </ul>
    </div>
  )
}

export default SurveyGrade
