import "./index.css"

import React from "react"

import { ReactComponent as AviaoDePapel } from "../../assets/aviao_de_papel.svg"

const SentSurvey = (): React.ReactElement => {
  return (
    <div className="sent-survey-container">
      Agradecemos sua avaliação!
      <AviaoDePapel/>
    </div>
  )
}

export default SentSurvey
