import { type Reducer } from "redux"

import { type OneState, OneTypes } from "./types"

const INITIAL_STATE: OneState = {
  sendSurveyOne: { loading: false },
  plate: { loading: false }
}

const reducer: Reducer<OneState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OneTypes.SET_DATA:
      return { ...state, sendSurveyOne: { ...state.sendSurveyOne, ...action.payload.data } }
    case OneTypes.CLEAR_DATA:
      return { ...state, data: INITIAL_STATE }

    case OneTypes.SEND_SURVEY_REQUEST:
      return { ...state, sendSurveyOne: { loading: true } }
    case OneTypes.SEND_SURVEY_SUCCESS:
    case OneTypes.SEND_SURVEY_FAILURE:
      return { ...state, sendSurveyOne: { response: action.payload, loading: false } }

    case OneTypes.SEARCH_PLATE_REQUEST:
      return { ...state, plate: { loading: true } }
    case OneTypes.SEARCH_PLATE_SUCCESS:
    case OneTypes.SEARCH_PLATE_FAILURE:
      return { ...state, plate: { response: action.payload, loading: false } }

    default:
      return state
  }
}

export default reducer
