import "./index.css"

import React from "react"
import { Outlet } from "react-router-dom"

import GenericHeader from "../../components/GenericHeader"

const Survey: React.FC = (): React.ReactElement => {
  return (
    <div className="generic-survey-container">
      <div className="generic-survey-background" />
      <div className="generic-survey-header">
        <GenericHeader />
      </div>
      <div className="generic-survey-body">
        <Outlet/>
      </div>
    </div>
  )
}

export default Survey
