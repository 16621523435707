import "./index.css"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input } from "antd"
import { Modal } from "antd/lib"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { bindActionCreators, type Dispatch } from "redux"

import KintoButton, { KintoButtonThemes } from "../../components/KintoButton"
import SurveyGrade from "../../components/SurveyGrade"
import { type ApplicationState } from "../../store"
import OneActions from "../../store/ducks/one/actions"
import { type SurveyOnePayload } from "../../store/ducks/one/sagas"
import { type OneState } from "../../store/ducks/one/types"

const { TextArea } = Input

type OwnProps = {
  onSubmit?: () => void
  redirectTo?: string
}

type StateProps = {
  one: OneState
}

type DispatchProps = {
  sendSurvey: (data: SurveyOnePayload) => void
  searchPlate: (data: { plate: string }) => void
}

type Props = StateProps & DispatchProps & OwnProps

type FormData = {
  grade: number
  comments: string
}

function mapStateToProps (state: ApplicationState): StateProps {
  return ({
    one: state.one
  })
}

function mapDispatchToProps (dispatch: Dispatch) {
  return bindActionCreators(OneActions, dispatch)
}

const SurveyOne: React.FC<Props> = (props): React.ReactElement => {
  const [formData] = Form.useForm()
  const [currentGrade, setCurrentGrade] = useState<number | undefined>()
  const [errorModal, contextHolder] = Modal.useModal()
  const navigate = useNavigate()
  const { uid } = useParams()
  const [searchParams, _setSearchParams] = useSearchParams()
  const grade = searchParams.get("grade")

  useEffect(() => {
    if (Number(grade)) setCurrentGrade(Number(grade))
    if (uid) { props.searchPlate({ plate: uid }) }
  }, [])

  const modalConfig = {
    title: "O envio falhou!",
    content: (
      <>
        O envio da sua resposta falhou. Por favor tente novamente mais tarde!
      </>
    )
  }

  const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
    <>
      {label} {required ? "" : <span style={{ color: "#BBB" }}>&nbsp;(Opcional)</span>}
    </>
  )

  useEffect(() => {
    const sendSurveyOne = props.one.sendSurveyOne
    if (!sendSurveyOne.loading && sendSurveyOne.response) {
      if (sendSurveyOne.response.error) void errorModal.error(modalConfig)
      else {
        if (props.onSubmit) props.onSubmit()
        if (props.redirectTo) navigate(props.redirectTo)
      }
    }
  }, [props.one.sendSurveyOne.loading])

  const handleSubmitSurvey = (values: FormData) => {
    const { grade, comments } = values
    props.sendSurvey({ uid: uid as string, grade, comments })
  }

  return (
    <div className="survey-one-container">
      <div className="survey-one-form">
        {props.one.plate.loading && <LoadingOutlined />}
        {(props.one.plate.response?.error && (<Navigate to={"/"} replace />))}
        {(props.one.plate.response?.data as IOnePlateDataResponseData)?.status === "ALREADY_DONE" && (<Navigate to={"/already-done"} replace />)}
        {(props.one.plate.response?.data as IOnePlateDataResponseData)?.status === "EXPIRED" && (<span>O prazo para responder essa pesquisa expirou.</span>)}
        {(props.one.plate.response?.data as IOnePlateDataResponseData)?.status === "WAITING_ANSWER" && (
          <Form
            onFinish={handleSubmitSurvey}
            form={formData}
            layout="vertical"
            requiredMark={customizeRequiredMark}
          >
            <Form.ErrorList />
            <Form.Item
              name="grade"
              label="Com base na sua experiência quanto ao atendimento realizado pela KINTO, qual a probabilidade de recomendar nossos serviços a um amigo ou familiar?"
              rules={[
                { required: true, message: "Campo obrigatório!" }
              ]}
              initialValue={(grade && Number(grade) && Number(grade) > 0 && Number(grade) <= 10) ? Number(grade) : undefined}
            >
              <SurveyGrade onChange={(value) => { setCurrentGrade(value) }} />
            </Form.Item>

            <Form.Item
              name="comments"
              label="Como podemos melhorar?"
              hidden={!currentGrade || currentGrade > 8}
              rules={[
                { required: Boolean(currentGrade && currentGrade <= 6), message: "Campo obrigatório!" }
              ]}
            >
              <TextArea showCount maxLength={350} rows={6}/>
            </Form.Item>

            <Form.Item label="">
              <KintoButton loading={props.one.sendSurveyOne.loading} theme={KintoButtonThemes.contained} htmlType="submit" block>Enviar</KintoButton>
            </Form.Item>
          </Form>
        )}
      </div>
      {contextHolder}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyOne)
