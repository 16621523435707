import "./App.css"

import { ConfigProvider } from "antd"
import React from "react"
import { Provider } from "react-redux"
import { RouterProvider } from "react-router-dom"

import router from "./router"
import store from "./store"

const App = function (): JSX.Element {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            // Disabled
            colorText: "#00708D",
            colorTextDisabled: "#97A7B1",
            colorBgContainerDisabled: "#EEEFF0",

            // Primary
            colorPrimary: "#00708D",
            colorPrimaryHover: "#1B809F",
            colorPrimaryActive: "#4493B1",

            // Default

            // Text
            colorBgTextHover: "#EEEFF0",
            colorBgTextActive: "#C8DBE3"
          }
        }}
      >
        <Provider store={store}>
          <RouterProvider router = { router() } />
        </Provider>
      </ConfigProvider>
    </div>
  )
}

export default App
