import { configureStore, type Store } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"

import { type OneState } from "./ducks/one/types"
import rootReducer from "./ducks/rootReducer"
import rootSaga from "./ducks/rootSaga"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApplicationState {
  one: OneState
}

const sagaMiddleware = createSagaMiddleware()

const store: Store<ApplicationState> = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

export default store
